import React from "react";
import './prin.css';
import Fond from '../../images/prin.png';

const Prin =()=>{
    return(
        <div className="prin">
         <div className="img">
         <img src={Fond} alt="" />
         </div>
         <div className="textA">
            <div></div>
            <h1>THE 2022</h1>
           <div></div>
         </div>
        </div>
    )
}
export default Prin;