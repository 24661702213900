import React from "react";
import './tabla.css';
const Tabla =()=>{
    return(
      <div className="tabla">
        <div className="puntaje">
            <h1>TORNEO 1vs1 EYE OF SKADI 2022</h1>
            <h1>QUE PASÓ AMSTER?? TE DESVENDÉ??</h1>
            <div className="tabA">  
                <div className="tablaG">
                    <div className="tablaP">
                        <div className="tabla 1">
                            <span className="span1">PARTIDOS</span>
                            <span className="span2">PA</span>
                            <span className="span3">Marcel</span>
                            <span className="span3">Yoshi</span>
                            <span className="span3">ONLY</span>
                            <span className="span3">SORA</span>
                            <span className="span3">RCR</span>
                            <span className="span3">VIKINGO</span>
                            <span className="span3">MIKI</span>
                            <span className="span4">Ma</span>
                            <span className="span4">Yo</span>
                            <span className="span4">ON</span>
                            <span className="span4">SO</span>
                            <span className="span4">RC</span>
                            <span className="span4">VI</span>
                            <span className="span4">MI</span>
                        </div>  
                        <div className="tabla 2">
                            <span className="span1">MARCEL</span>
                            <span className="span2">MA</span>
                            <h1>-</h1>
                            <h1>0</h1>
                            <h1>0</h1>
                            <h1>0</h1>
                            <h1>0</h1>
                            <h1>0</h1>
                            <h1>0</h1>
                        </div>  
                        <div className="tabla 3">
                            <span className="span1">YOSHI </span>
                            <span className="span2">YO</span>
                            <h1>0</h1>
                            <h1>-</h1>
                            <h1>0</h1>
                            <h1>0</h1>
                            <h1>0</h1>
                            <h1>0</h1>
                            <h1>0</h1>
                        </div>  
                        <div className="tabla 4">
                            <span className="span1">ONLY</span>
                            <span className="span2">ON</span>
                            <h1>0</h1>
                            <h1>0</h1>
                            <h1>-</h1>
                            <h1>0</h1>
                            <h1>0</h1>
                            <h1>0</h1>
                            <h1>0</h1>
                        </div> 
                        <div className="tabla 5">
                            <span className="span1">SORA</span>
                            <span className="span2">SO</span>
                            <h1>0</h1>
                            <h1>0</h1>
                            <h1>0</h1>
                            <h1>-</h1>
                            <h1>0</h1>
                            <h1>0</h1>
                            <h1>0</h1>
                        </div>
                        <div className="tabla 6">
                            <span className="span1">RCR</span>
                            <span className="span2">RC</span>
                            <h1>0</h1>
                            <h1>0</h1>
                            <h1>0</h1>
                            <h1>0</h1>
                            <h1>-</h1>
                            <h1>0</h1>
                            <h1>0</h1>
                        </div>
                        <div className="tabla 6">
                            <span className="span1">VIKINGO</span>
                            <span className="span2">VI</span>
                            <h1>0</h1>
                            <h1>0</h1>
                            <h1>0</h1>
                            <h1>0</h1>
                            <h1>0</h1>
                            <h1>-</h1>
                            <h1>0</h1>
                        </div>
                        <div className="tabla 7">
                            <span className="span1">MIKI</span>
                            <span className="span2">MI</span>
                            <h1>0</h1>
                            <h1>0</h1>
                            <h1>0</h1>
                            <h1>0</h1>
                            <h1>0</h1>
                            <h1>0</h1>
                            <h1>-</h1>
                        </div>
                        <div className="tabla 4">
                            <span className="span1">PUNTAJE</span>
                            <span className="span2">PU</span>
                            <h1>0</h1>
                            <h1>0</h1>
                            <h1>0</h1>
                            <h1>0</h1>
                            <h1>0</h1>
                            <h1>0</h1>
                            <h1>0</h1>
                        </div>
                        <div className="tabla 4">
                            <span className="span1">KD</span>
                            <span className="span2">KD</span>
                            <h1>0</h1>
                            <h1>0</h1>
                            <h1>0</h1>
                            <h1>0</h1>
                            <h1>0</h1>
                            <h1>0</h1>
                            <h1>0</h1>
                        </div>
                    </div>
                </div> 
            </div>
        </div>
      </div>
    )
}
export default Tabla;