import React from 'react';
import './App.css';
import Encabezado from './componentes/encabezado/encabezado';
import Prin from './componentes/prin/prin';
import Tabla from './componentes/tabla/tabla';
function App() {

  return (
    <div className="App">
      <video autoPlay muted preload="auto" loop playsInline poster="https://cdn.cloudflare.steamstatic.com/apps/dota2/videos/dota_react/international2022/diretide/diretide_snow_loop.jpg" >
        <source type="video/webm" src="https://cdn.cloudflare.steamstatic.com/apps/dota2/videos/dota_react/international2022/diretide/diretide_snow_loop2.webm"/>
        <source type="video/mp4" src="https://cdn.cloudflare.steamstatic.com/apps/dota2/videos/dota_react/international2022/diretide/diretide_snow_loop.mp4"/>
      </video> 
      <Encabezado/>
      <Prin/>
      <Tabla/>
      
    </div>
  );
}

export default App;