import React from "react";
import './encabezado.css';
import logoD from '../../images/logoDota.png'
const Encabezado =()=>{
    return(
        <div className="encabezado">
           
            <div className="encL">
                <a href="#">
                    <img src={logoD} alt="" />
                    <span>DOTA 2</span>
                </a>
            </div>
            <div className="encC">
                <a href="#"><span>JUEGO</span></a>
                <a href="#"><span>HÉROES</span></a>
                <a href="#"><span>NOTICIAS</span></a>
                <a href="#"><span>E-SPORTS</span></a>
            </div>
            <div className="encR">
            <a href="#"><span>INICIAR SESIÓN</span></a>
            </div>
               
        </div>
    )
}
export default Encabezado;